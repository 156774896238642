import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "aktKomponen";
const modelPath = "/aktivitas/";

class AktivitasKomponen extends ModelBase {
  constructor() {
    let model = {
      id: null,
      aktivitas: {
        tanggal: "",
        preceptor: "",
        preceptor_id: null,
        pspd_id: null,
        pspd: "",
        jenis_keterampilan: "",
        kompetensi: "",
        perawatan: {
          id: null,
          kode_bill: "",
          jenis_perawatan: "",
          pasien: {
            id: null,
            name: "",
            no_rm: ""
          }
        },
      },
      catatan: "",
      nilai_sikap: null,
      lokasi: { id: null, nama: "" },
      verifikasi: "",
      diagnosis: { id: null, nama: "" },
      pemeriksaan: { id: null, nama: "" },
      tatalaksana: { id: null, nama: "" },
      keterampilan: { id: null, nama: "" },
      foto_set: [],
      foto: null
    };
    super(modelName, model, ['verifikasi'], [], modelPath);
  }

  getLoadData(data) {
    if (data.keterampilan) {
      let nilai_sikap = data['nilai_sikap'];
      data['nilai_sikap'] = nilai_sikap? parseFloat(nilai_sikap):nilai_sikap;
    }
    return JSON.parse(JSON.stringify(data));
  }

  getErrorMap() {
    let errorMap = super.getErrorMap();
    errorMap.nilai_sikap = "";
    return errorMap;
  }

  validate(field) {
    if (field === "nilai_sikap") {
      this.observables.errorMap.nilai_sikap = "";
      this.calcValidity();
    } else {
      super.validate(field);
    }
  }

  getPayload() {
    let aktivitas = this.observables.aktKomponen;
    let data = {
      id: aktivitas.id,
      nilai_sikap: aktivitas.nilai_sikap,
      verifikasi: aktivitas.verifikasi
    };
    return data;
  }
}

export default AktivitasKomponen;
